<template>
  <div>
    <OwnersWebsiteNavRequests/>
    <router-view></router-view>
  </div>
</template>

<script>

import OwnersWebsiteNavRequests from "@/modules/fivesClub/components/catalogs/ownersWebsite/OwnersWebsiteNavRequests"

export default {
  components: {
    OwnersWebsiteNavRequests
  },
  computed: {
    currentRouteName() {
      return this.$route.name
    }
  },
  methods: {
    activeClass(...names) {
      for (let name of names){
        return name == this.$route.name ? 'active' : ''
      }
    }
  },
};
</script>

<style scoped>
.navbar {
  background-color: #fff;
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.1);
  box-shadow: 0 0 8px 0 rgba(#22292f, 0.14);

}

.navbar-list {
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: space-between;
}

.navbar-item {
  margin-right: 10px;
}

.navbar-item a {
  /* color: #111111; */
  text-decoration: none;
}

@media (max-width: 767px) {
  .navbar-list {
    flex-direction: column;
  }

  .navbar-item {
    margin-bottom: 10px;
  }
}

.button {
  display: inline-block;
  padding: 10px 20px;
  background-color: transparent;
  color: #000;
  text-decoration: none;
  border-radius: 4px;
  border: none;
  transition: background-color 0.3s ease;
}


.button:hover, .button.active {
  background-color: #333366;
  color: #fff;

}


</style>